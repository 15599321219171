import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const analyticRouter: Array<RouteRecordRaw> = [
  {
    path: '/analytics/daily',
    component: Layout,
    // redirect: 'noRedirect',
    meta: {
      title: 'analytics',
      icon: '#icon-analysis'
    },
    children: [
      {
        path: 'revenue',
        component: () => import(/* webpackChunkName: "revenue-daily" */ '@/views/analytics/revenue/Index.vue'),
        name: 'list-daily-renvenue',
        meta: {
          title: 'DailyRevenue',
          icon: '#icon-analysis'
        }
      },
      {
        path: 'channel',
        component: () => import(/* webpackChunkName: "channel-daily" */ '@/views/analytics/channel/Index.vue'),
        name: 'list-daily-channel',
        meta: {
          title: 'DailyChannel',
          icon: '#icon-analysis'
        }
      },
      {
        path: 'subscriber-source',
        component: () => import(/* webpackChunkName: "channel-daily" */ '@/views/analytics/channel/components/SubscriptionReferralsList.vue'),
        name: 'list-channel-subscription-referral',
        meta: {
          title: 'DailyChannelSubscriptionReferral',
          icon: '#icon-analysis',
          hidden: true
        }
      },
      {
        path: 'refund-statistics',
        component: () => import(/* webpackChunkName: "channel-daily" */ '@/views/analytics/channel/components/RefundStatisticsList.vue'),
        name: 'list-daily-channel-refund-statistics',
        meta: {
          title: 'DailyChannelRefundStatistics',
          icon: '#icon-analysis',
          hidden: true
        }
      },
      {
        path: 'campaign',
        component: () => import(/* webpackChunkName: "channel-daily" */ '@/views/analytics/campaign/Index.vue'),
        name: 'list-daily-campaign',
        meta: {
          title: 'DailyCampaign',
          icon: '#icon-analysis'
        }
      },
      {
        path: 'daily-data',
        component: () => import(/* webpackChunkName: "daily-data" */ '@/views/daliy-data/List.vue'),
        name: 'list-daily-data',
        meta: {
          title: 'daily-data',
          icon: '#icon-analysis'
        }
      }
    ]
  }
];

export default analyticRouter;
