import VAES from './ase';
import Cookies from 'js-cookie';

export enum StorageType{
  local,
  session,
  cookie
}

interface VStorageInterface{
  rcSetItem<T>(type: StorageType, key: string, value: T): void
  rcGetItem(type: StorageType, key: string): string | null
  rcRemoveItem(type: StorageType, key: string): void
}

class VStorage implements VStorageInterface {
  private static instance: VStorage
  static shared() {
    if (!this.instance) {
      this.instance = new VStorage();
    }
    return this.instance;
  }

  /**
   * @description: 本地保存数据AES加密处理
   * @param {StorageType} type localStorage 和 sessionStorage 选择
   * @param {string} key
   * @param {T} value
   */
  rcSetItem<T>(type: StorageType = StorageType.local, key: string, value: T) {
    const valueAes = VAES.encrypt(JSON.stringify(value));

    if (type === StorageType.local) {
      localStorage.setItem(key, valueAes);
      return;
    }

    if (type === StorageType.session) {
      sessionStorage.setItem(key, valueAes);
      return;
    }

    Cookies.set(key, valueAes);
  }

  rcGetItem(type: StorageType = StorageType.local, key: string) {
    if (type === StorageType.local) return VAES.decrypt(localStorage.getItem(key));

    if (type === StorageType.session) return VAES.decrypt(sessionStorage.getItem(key));

    return VAES.decrypt(Cookies.get(key) ?? '');
  }

  rcRemoveItem(type: StorageType = StorageType.local, key: string) {
    if (type === StorageType.local) {
      localStorage.removeItem(key);
      return;
    }

    if (type === StorageType.session) {
      sessionStorage.removeItem(key);
      return;
    }

    Cookies.remove(key);
  }
}

export default VStorage.shared();
