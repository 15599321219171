import { request } from './utils';

import {
  PermissionsResponse
} from './interfaces';

export function getPermissions(): Promise<PermissionsResponse> {
  return request({
    url: '/permissions',
    method: 'get'
  });
}
