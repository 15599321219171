import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const blocksRouter: Array<RouteRecordRaw> = [
  {
    path: '/blocks',
    component: Layout,
    // redirect: 'noRedirect',
    meta: {
      title: 'blocks',
      icon: '#icon-block'
    },
    children: [
      {
        path: 'advertisements/edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "admin-edit" */ '@/views/advertisements/Edit.vue'),
        name: 'edit-advertisement',
        meta: {
          title: 'editAdvertisements',
          noCache: true,
          activeMenu: '/advertisements',
          hidden: true
        }
      },
      {
        path: 'advertisements/:id(\\d+)',
        component: () => import(/* webpackChunkName: "advertisements-show" */ '@/views/advertisements/Show.vue'),
        name: 'show-advertisement',
        meta: {
          title: 'showAdvertisement',
          noCache: true,
          activeMenu: '/advertisements',
          hidden: true
        }
      },
      {
        path: 'advertisements',
        component: () => import(/* webpackChunkName: "admin-list" */ '@/views/advertisements/List.vue'),
        name: 'list-advertisements',
        meta: {
          title: 'advertisements',
          icon: '#icon-advertisement'
        }
      },
      {
        path: 'banners/create',
        component: () => import(/* webpackChunkName: "banner-create" */ '@/views/banners/Create.vue'),
        name: 'create-banner',
        meta: {
          title: 'createBanner',
          activeMenu: '/banners',
          hidden: true
        }
      },
      {
        path: 'banners/edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "banner-edit" */ '@/views/banners/Edit.vue'),
        name: 'edit-banner',
        meta: {
          title: 'editBanner',
          noCache: true,
          activeMenu: '/banners',
          hidden: true
        }
      },
      {
        path: 'banners/:id(\\d+)',
        component: () => import(/* webpackChunkName: "banner-show" */ '@/views/banners/Show.vue'),
        name: 'show-banner',
        meta: {
          title: 'showBanner',
          noCache: true,
          activeMenu: '/banners',
          hidden: true
        }
      },
      {
        path: 'banners',
        component: () => import(/* webpackChunkName: "banner-list" */ '@/views/banners/List.vue'),
        name: 'list-banners',
        meta: {
          title: 'banners',
          icon: '#icon-banner'
        }
      },
      {
        path: 'create',
        component: () => import(/* webpackChunkName: "block-create" */ '@/views/blocks/Create.vue'),
        name: 'create-block',
        meta: {
          title: 'createBlock',
          activeMenu: '/blocks',
          hidden: true
        }
      },
      {
        path: 'edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "block-edit" */ '@/views/blocks/Edit.vue'),
        name: 'edit-block',
        meta: {
          title: 'editBlock',
          noCache: true,
          activeMenu: '/blocks',
          hidden: true
        }
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "block-list" */ '@/views/blocks/List.vue'),
        name: 'list-blocks',
        meta: {
          title: 'blocks',
          icon: '#icon-block'
        }
      },
      {
        path: ':id(\\d+)',
        component: () => import(/* webpackChunkName: "block-show" */ '@/views/blocks/Show.vue'),
        name: 'show-block',
        meta: {
          title: 'showBlock',
          noCache: true,
          activeMenu: '/blocks',
          hidden: true
        }
      },
      {
        path: 'informations/:id(\\d+)/edit',
        component: () => import(/* webpackChunkName: "information-edit" */ '@/views/informations/Edit.vue'),
        name: 'edit-information',
        meta: {
          title: 'editInformation',
          noCache: true,
          activeMenu: '/informations',
          hidden: true
        }
      },
      {
        path: 'informations',
        component: () => import(/* webpackChunkName: "information-list" */ '@/views/informations/List.vue'),
        name: 'list-informations',
        meta: {
          title: 'informations',
          icon: '#icon-info'
        }
      }
    ]
  }
];

export default blocksRouter;
