
import { useStore } from '@/store';
import { SettingsActionTypes } from '@/store/modules/settings/action-types';
import { defineComponent, reactive, toRefs, watch } from 'vue';
import ThemePicker from '@/components/theme-picker/Index.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {
    ThemePicker
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const state = reactive({
      fixedHeader: store.state.settings.fixedHeader,
      showTagsView: store.state.settings.showTagsView,
      showSidebarLogo: store.state.settings.showSidebarLogo,
      sidebarTextTheme: store.state.settings.sidebarTextTheme,
      themeChange: (value: string) => {
        store.dispatch(SettingsActionTypes.ACTION_CHANGE_SETTING, { key: 'theme', value });
      }
    });

    watch(() => state.fixedHeader, (value) => {
      store.dispatch(SettingsActionTypes.ACTION_CHANGE_SETTING, { key: 'fixedHeader', value });
    });

    watch(() => state.showTagsView, (value) => {
      store.dispatch(SettingsActionTypes.ACTION_CHANGE_SETTING, { key: 'showTagsView', value });
    });

    watch(() => state.showSidebarLogo, (value) => {
      console.log(value);

      store.dispatch(SettingsActionTypes.ACTION_CHANGE_SETTING, { key: 'showSidebarLogo', value });
    });

    watch(() => state.sidebarTextTheme, (value) => {
      store.dispatch(SettingsActionTypes.ACTION_CHANGE_SETTING, { key: 'sidebarTextTheme', value });
    });

    return {
      t,
      ...toRefs(state)
    };
  }
});
