/*
 * @Description: 白名单
 * @Author: ZY
 * @Date: 2020-12-28 09:38:57
 * @LastEditors: ZY
 * @LastEditTime: 2020-12-28 14:07:34
 */

const whiteList = [
  '/login',
  '/auth-redirect',
  '/404',
  '/401'
];
export default whiteList;
