/*
 * @Description: 粘贴板
 * @Author: scy
 * @Date: 2021-01-21 20:13:03
 * @LastEditors: scy
 * @LastEditTime: 2021-01-21 21:24:27
 */
import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const ProfileRouter: Array<RouteRecordRaw> = [
  {
    path: '/clipboard',
    component: Layout,
    // redirect: 'noredirect',
    meta: {
      title: 'clipboard',
      icon: '#icon-copy',
      hidden: true
    },
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "clipboard" */ '@/views/clipboard/Index.vue'),
        name: 'Clipboard',
        meta: {
          title: 'clipboard',
          icon: '#icon-copy'
        }
      }
    ]
  }
];

export default ProfileRouter;
