import { request } from './utils';

import {
  OrdersResponse,
  GetOrdersRequestOptions,
  GetOrderRequestOptions,
  UpdateOrderRequestOptions,
  OrderResponse,
  GetOrderSuccessRatioRequestOptions,
  OrderSuccessRatioResponse
} from './interfaces';

export function getOrders({ query }: GetOrdersRequestOptions): Promise<OrdersResponse> {
  return request({
    url: '/orders',
    method: 'get',
    params: query
  });
}

export function getOrder({ id }: GetOrderRequestOptions): Promise<OrderResponse> {
  return request({
    url: `/orders/${id}`,
    method: 'get'
  });
}

export function updateOrder({ id, data }: UpdateOrderRequestOptions): Promise<OrdersResponse> {
  return request({
    url: `/orders/${id}`,
    method: 'put',
    data
  });
}
