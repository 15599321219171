import { request } from './utils';

import {
  SubscriptionsResponse,
  GetSubscriptionsRequestOptions,
  SubscriptionChannelStatisticResponse
} from './interfaces';

export function getSubscriptions({ query }: GetSubscriptionsRequestOptions): Promise<SubscriptionsResponse> {
  return request({
    url: '/subscriptions',
    method: 'get',
    params: query
  });
}

export function getSubscriptionsChannelStatistic({ query }: GetSubscriptionsRequestOptions): Promise<SubscriptionChannelStatisticResponse> {
  return request({
    url: '/subscriptions/channelStatistic',
    method: 'get',
    params: query
  });
}
