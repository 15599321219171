import { createHTTPClient } from '@/utils/http';
import decamelize from 'decamelize';

export const request = createHTTPClient({ baseURL: process.env.VUE_APP_BASE_API });
// export const request = createHTTPClient({ baseURL: 'http://us.model.kobe/api/v1/admin' });
// export const request = createHTTPClient({ baseURL: 'http://us.model.leo/api/v1/admin' });

export function createFormData(object = {}) {
  const formData = new FormData();

  Object.entries(object)
    .forEach(([key, value]) => {
      const formattedKey = decamelize(`${key}`);
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      formData.append(formattedKey, value);
    });

  return formData;
}
