import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, TransitionGroup as _TransitionGroup, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e61e124"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "no-redirect"
}
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item")!
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb")!

  return (_openBlock(), _createBlock(_component_el_breadcrumb, {
    class: "app-breadcrumb",
    "separator-class": "el-icon-arrow-right"
  }, {
    default: _withCtx(() => [
      _createVNode(_TransitionGroup, { name: "breadcrumb" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, (item, index) => {
            return (_openBlock(), _createBlock(_component_el_breadcrumb_item, {
              key: item.path
            }, {
              default: _withCtx(() => [
                (item.redirect === 'noredirect' || index === _ctx.breadcrumbs.length-1)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.t('route.' + item.meta.title)), 1))
                  : (_openBlock(), _createElementBlock("a", {
                      key: 1,
                      onClick: _withModifiers(($event: any) => (_ctx.handleLink(item)), ["prevent"])
                    }, _toDisplayString(_ctx.t('route.' + item.meta.title)), 9, _hoisted_2))
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}