import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const CommentRouter: Array<RouteRecordRaw> = [
  {
    path: '/comments',
    component: Layout,
    // redirect: 'noredirect',
    meta: {
      title: 'comments',
      icon: '#icon-comment'
    },
    children: [
      {
        path: '',
        component: () => import('@/views/comments/List.vue'),
        name: 'list-comments',
        meta: {
          title: 'comments',
          icon: '#icon-comment'
        }
      },
      {
        path: 'comments/reports',
        component: () => import('@/views/comments/Report.vue'),
        name: 'list-comment-reports',
        meta: {
          title: 'commentReports',
          icon: '#icon-report'
        }
      }
    ]
  }
];

export default CommentRouter;
