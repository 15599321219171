import { request } from './utils';

import {
  // QuestionsResponse,
  // GetQuestionsRequestOptions,
  GetDailyDataRequestOptions,
  DailyDataResponse
} from './interfaces';

// export function getQuestions({ query }: GetQuestionsRequestOptions): Promise<QuestionsResponse> {
//   return request({
//     url: '/questions',
//     method: 'get',
//     params: query
//   });
// }

export function getDailyData({ query }: GetDailyDataRequestOptions): Promise<DailyDataResponse> {
  return request({
    url: '/analytics/data/daily',
    method: 'get',
    params: query
  });
}
