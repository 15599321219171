/*
 * @Description: app mutations type
 * @Author: ZY
 * @Date: 2020-12-23 10:25:37
 * @LastEditors: ZY
 * @LastEditTime: 2020-12-25 10:43:53
 */

export enum SettingsMutationTypes {
  CHANGE_SETTING = 'CHANGE_SETTING',
}
