import { request } from './utils';

import {
  InformationResponse,
  InformationsResponse,
  GetInformationRequestOptions,
  GetInformationsRequestOptions,
  CreateInformationRequestOptions,
  UpdateInformationRequestOptions
} from './interfaces';

export function getInformation({ informationId }: GetInformationRequestOptions): Promise<InformationResponse> {
  return request({
    url: `/informations/${informationId}`,
    method: 'get'
  });
}

export function getInformations({ query }: GetInformationsRequestOptions): Promise<InformationsResponse> {
  return request({
    url: '/informations',
    method: 'get',
    params: query
  });
}

export function createInformation({ data }: CreateInformationRequestOptions): Promise<InformationResponse> {
  return request({
    url: '/informations',
    method: 'post',
    data
  });
}

export function updateInformation({ informationId, data }: UpdateInformationRequestOptions): Promise<InformationResponse> {
  return request({
    url: `/informations/${informationId}`,
    method: 'put',
    data
  });
}
