/*
 * @Description:
 * @Author: ZY
 * @Date: 2020-12-25 14:33:12
 * @LastEditors: ZY
 * @LastEditTime: 2020-12-25 14:33:38
 */
export enum PermissionActionType{
    ACTION_SET_ROUTES = 'ACTION_SET_ROUTES'
}
