import { request } from './utils';

import {
  DealResponse,
  DealsResponse,
  GetDealRequestOptions,
  GetDealsRequestOptions,
  CreateDealRequestOptions,
  UpdateDealRequestOptions,
  DeleteDealRequestOptions,
  SucceededResponse
} from './interfaces';

export function getDeal({ dealId }: GetDealRequestOptions): Promise<DealResponse> {
  return request({
    url: `/deals/${dealId}`,
    method: 'get'
  });
}

export function getDeals({ query }: GetDealsRequestOptions): Promise<DealsResponse> {
  return request({
    url: '/deals',
    method: 'get',
    params: query
  });
}

export function createDeal({ data }: CreateDealRequestOptions): Promise<DealResponse> {
  return request({
    url: '/deals',
    method: 'post',
    data
  });
}

export function updateDeal({ dealId, data }: UpdateDealRequestOptions): Promise<DealResponse> {
  return request({
    url: `/deals/${dealId}`,
    method: 'put',
    data
  });
}

export function deleteDeal({ dealId }: DeleteDealRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/deals/${dealId}`,
    method: 'delete'
  });
}
