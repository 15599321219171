class Keys {
  static sidebarStatusKey = 'admin-sidebarStatusKey'
  static languageKey = 'admin-languageKey'
  static sizeKey = 'admin-sizeKey'
  static tokenKey = 'admin-access-token'
  static aseKey = 'admin-ase-key'
  static permissionsKey = 'admin-permissions'
}

export default Keys;
