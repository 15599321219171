/*
 * @Description: app actions type
 * @Author: ZY
 * @Date: 2020-12-23 10:25:37
 * @LastEditors: ZY
 * @LastEditTime: 2020-12-25 10:55:13
 */

export enum SettingsActionTypes {
  ACTION_CHANGE_SETTING = 'ACTION_CHANGE_SETTING',
}
