import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const CustomRouter: Array<RouteRecordRaw> = [
  {
    path: '/questions',
    component: Layout,
    // redirect: 'noredirect',
    meta: {
      title: 'custom',
      icon: '#icon-custom'
    },
    children: [
      {
        path: 'create',
        component: () => import(/* webpackChunkName: "question-create" */ '@/views/questions/Create.vue'),
        name: 'create-question',
        meta: {
          title: 'createQuestion',
          activeMenu: '/questions',
          hidden: true
        }
      },
      {
        path: ':id(\\d+)/edit',
        component: () => import(/* webpackChunkName: "question-edit" */ '@/views/questions/Edit.vue'),
        name: 'edit-question',
        meta: {
          title: 'editQuestion',
          noCache: true,
          activeMenu: '/questions',
          hidden: true
        }
      },
      {
        path: ':id(\\d+)',
        component: () => import(/* webpackChunkName: "question-show" */ '@/views/questions/Show.vue'),
        name: 'show-question',
        meta: {
          title: 'showQuestion',
          noCache: true,
          activeMenu: '/questions',
          hidden: true
        }
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "question-list" */ '@/views/questions/List.vue'),
        name: 'list-questions',
        meta: {
          title: 'questions',
          icon: '#icon-question'
        }
      },
      {
        path: 'supports/:id(\\d+)',
        component: () => import(/* webpackChunkName: "support-show" */ '@/views/supports/Show.vue'),
        name: 'show-support',
        meta: {
          title: 'showSupport',
          noCache: true,
          activeMenu: '/supports',
          hidden: true
        }
      },
      {
        path: 'supports',
        component: () => import(/* webpackChunkName: "support-list" */ '@/views/supports/List.vue'),
        name: 'list-supports',
        meta: {
          title: 'supports',
          icon: '#icon-support'
        }
      }
    ]
  }
];

export default CustomRouter;
