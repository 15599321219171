
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  emits: ['toggle-click'],
  setup(_, context) {
    const toggleClick = () => {
      context.emit('toggle-click');
    };
    return {
      toggleClick
    };
  }
});
