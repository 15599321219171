import { request } from './utils';

import {
  PromotionResponse,
  PromotionsResponse,
  GetPromotionRequestOptions,
  GetPromotionsRequestOptions,
  CreatePromotionRequestOptions,
  UpdatePromotionRequestOptions,
  DeletePromotionRequestOptions,
  UpdatePromotionPlansRequestOptions,
  SucceededResponse
} from './interfaces';

export function getPromotion({ promotionId }: GetPromotionRequestOptions): Promise<PromotionResponse> {
  return request({
    url: `/promotions/${promotionId}`,
    method: 'get'
  });
}

export function getPromotions({ query }: GetPromotionsRequestOptions): Promise<PromotionsResponse> {
  return request({
    url: '/promotions',
    method: 'get',
    params: query
  });
}

export function createPromotion({ data }: CreatePromotionRequestOptions): Promise<PromotionResponse> {
  return request({
    url: '/promotions',
    method: 'post',
    data
  });
}

export function updatePromotion({ promotionId, data }: UpdatePromotionRequestOptions): Promise<PromotionResponse> {
  return request({
    url: `/promotions/${promotionId}`,
    method: 'put',
    data
  });
}

export function deletePromotion({ promotionId }: DeletePromotionRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/promotions/${promotionId}`,
    method: 'delete'
  });
}

export function updatePromotionPlans({ promotionId, data }: UpdatePromotionPlansRequestOptions): Promise<PromotionResponse> {
  return request({
    url: `/promotions/${promotionId}/plans`,
    method: 'post',
    data
  });
}
