import { request } from './utils';

import {
  BlockResponse,
  BlocksResponse,
  GetBlockRequestOptions,
  GetBlocksRequestOptions,
  CreateBlockRequestOptions,
  UpdateBlockRequestOptions,
  DeleteBlockRequestOptions,
  SucceededResponse,
  UpdateBlockAdvertisementRequestOptions,
  UpdateBlockVideosRequestOptions,
  UpdateBlockModelsRequestOptions
} from './interfaces';

export function getBlock({ blockId }: GetBlockRequestOptions): Promise<BlockResponse> {
  return request({
    url: `/blocks/${blockId}`,
    method: 'get'
  });
}

export function getBlocks({ query }: GetBlocksRequestOptions): Promise<BlocksResponse> {
  return request({
    url: '/blocks',
    method: 'get',
    params: query
  });
}

export function createBlock({ data }: CreateBlockRequestOptions): Promise<BlockResponse> {
  return request({
    url: '/blocks',
    method: 'post',
    data
  });
}

export function updateBlock({ blockId, data }: UpdateBlockRequestOptions): Promise<BlockResponse> {
  return request({
    url: `/blocks/${blockId}`,
    method: 'put',
    data
  });
}

export function deleteBlock({ blockId }: DeleteBlockRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/blocks/${blockId}`,
    method: 'delete'
  });
}

export function updateBlockVideos({ blockId, data }: UpdateBlockVideosRequestOptions): Promise<BlockResponse> {
  return request({
    url: `/blocks/${blockId}/videos`,
    method: 'post',
    data
  });
}

export function updateBlockModels({ blockId, data }: UpdateBlockModelsRequestOptions): Promise<BlockResponse> {
  return request({
    url: `/blocks/${blockId}/models`,
    method: 'post',
    data
  });
}

export function updateBlockPhotos({ blockId, data }: UpdateBlockRequestOptions): Promise<BlockResponse> {
  return request({
    url: `/blocks/${blockId}/photos`,
    method: 'post',
    data
  });
}

export function updateBlockAdvertisement({ blockId, data }: UpdateBlockAdvertisementRequestOptions): Promise<BlockResponse> {
  return request({
    url: `/blocks/${blockId}/advertisements`,
    method: 'post',
    data
  });
}
