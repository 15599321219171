import storage, { StorageType } from './storage';
import Keys from '@/constant/key';

import { Permission } from '@/interfaces/Admin';

export const getPermissions = (): Permission[] => JSON.parse(storage.rcGetItem(
  StorageType.local,
  Keys.permissionsKey
));

export const setPermissions = (permissions: Permission[] = []) => storage.rcSetItem(
  StorageType.local,
  Keys.permissionsKey,
  permissions
);

export const removePermissions = () => storage.rcRemoveItem(
  StorageType.local,
  Keys.permissionsKey
);
