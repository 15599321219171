import { request } from './utils';

import {
  GetSegpaysRequestOptions,
  SegpaysResponse,
  DeleteSegpayRequestOptions,
  SucceededResponse,
  GetSegpayRequestOptions,
  SegpayResponse,
  UpdateSegpayRequestOptions
} from './interfaces';

export function getSegpay({ id }: GetSegpayRequestOptions): Promise<SegpayResponse> {
  return request({
    url: `/segpay-payout-reports/${id}`,
    method: 'get'
  });
}

export function getSegpays({ query }: GetSegpaysRequestOptions): Promise<SegpaysResponse> {
  return request({
    url: '/segpay-payout-reports',
    method: 'get',
    params: query
  });
}

export function createSegpay({ data }: GetSegpaysRequestOptions): Promise<SegpaysResponse> {
  return request({
    url: '/segpay-payout-reports',
    method: 'post',
    data
  });
}

export function updateSegpay({ id, data }: UpdateSegpayRequestOptions): Promise<SegpayResponse> {
  return request({
    url: `/segpay-payout-reports/${id}`,
    method: 'put',
    data
  });
}

export function deleteSegpay({ id }: DeleteSegpayRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/segpay-payout-reports/${id}`,
    method: 'delete'
  });
}
