import { request } from './utils';

import {
  IpWhitelistResponse,
  GetIpWhitelistRequestOptions,
  CreateIPAdressRequestOptions,
  UpdateIpAddressRequestOptions,
  DeleteIpAddressRequestOptions,
  SucceededResponse,
  GetIpWhitelistsRequestOptions,
  IpWhitelistsResponse
} from './interfaces';

export function getIpWhitelist({ whitelistId }: GetIpWhitelistRequestOptions): Promise<IpWhitelistResponse> {
  return request({
    url: `/ipwhitelists/${whitelistId}`,
    method: 'get'
  });
}

export function getIpWhitelists({ query }: GetIpWhitelistsRequestOptions = {}): Promise<IpWhitelistsResponse> {
  return request({
    url: '/ipwhitelists',
    method: 'get',
    params: query
  });
}

export function createIPAdress({ data }: CreateIPAdressRequestOptions): Promise<IpWhitelistResponse> {
  return request({
    url: '/ipwhitelists',
    method: 'post',
    data
  });
}

export function updateIpAddress({ whitelistId, data }: UpdateIpAddressRequestOptions): Promise<IpWhitelistResponse> {
  return request({
    url: `/ipwhitelists/${whitelistId}`,
    method: 'put',
    data
  });
}

export function deleteIpAddress({ whitelistId }: DeleteIpAddressRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/ipwhitelists/${whitelistId}`,
    method: 'delete'
  });
}
