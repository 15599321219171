import { request } from './utils';

import {
  TagResponse,
  TagsResponse,
  GetTagRequestOptions,
  GetTagsRequestOptions,
  CreateTagRequestOptions,
  UpdateTagRequestOptions,
  DeleteTagRequestOptions,
  SucceededResponse
} from './interfaces';

export function getTag({ tagId }: GetTagRequestOptions): Promise<TagResponse> {
  return request({
    url: `/tags/${tagId}`,
    method: 'get'
  });
}

export function getTags({ query }: GetTagsRequestOptions = {}): Promise<TagsResponse> {
  return request({
    url: '/tags',
    method: 'get',
    params: query
  });
}

export function createTag({ data }: CreateTagRequestOptions): Promise<TagResponse> {
  return request({
    url: '/tags',
    method: 'post',
    data
  });
}

export function updateTag({ tagId, data }: UpdateTagRequestOptions): Promise<TagResponse> {
  return request({
    url: `/tags/${tagId}`,
    method: 'put',
    data
  });
}

export function deleteTag({ tagId }: DeleteTagRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/tags/${tagId}`,
    method: 'delete'
  });
}
