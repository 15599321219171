import { request } from './utils';

import {
  PostResponse,
  PostsResponse,
  GetPostRequestOptions,
  GetPostsRequestOptions,
  CreatePostRequestOptions,
  UpdatePostRequestOptions
} from './interfaces';

export function getPost({ postId }: GetPostRequestOptions): Promise<PostResponse> {
  return request({
    url: `/posts/${postId}`,
    method: 'get'
  });
}

export function getPosts({ query }: GetPostsRequestOptions): Promise<PostsResponse> {
  return request({
    url: '/posts',
    method: 'get',
    params: query
  });
}

export function createPost({ data }: CreatePostRequestOptions): Promise<PostResponse> {
  return request({
    url: '/posts',
    method: 'post',
    data
  });
}

export function updatePost({ postId, data }: UpdatePostRequestOptions): Promise<PostResponse> {
  return request({
    url: `/posts/${postId}`,
    method: 'put',
    data
  });
}
