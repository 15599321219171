import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const userRouter: Array<RouteRecordRaw> = [
  {
    path: '/users',
    component: Layout,
    // redirect: 'noRedirect',
    meta: {
      title: 'users',
      icon: '#icon-user'
    },
    children: [
      {
        path: 'edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "user-edit" */ '@/views/users/Edit.vue'),
        name: 'edit-user',
        meta: {
          title: 'editUser',
          noCache: true,
          activeMenu: '/users',
          hidden: true
        }
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "user-list" */ '@/views/users/List.vue'),
        name: 'list-users',
        meta: {
          title: 'users',
          icon: '#icon-user'
        }
      },
      {
        path: ':id(\\d+)',
        component: () => import(/* webpackChunkName: "user-show" */ '@/views/users/Show.vue'),
        name: 'show-user',
        meta: {
          title: 'showUser',
          noCache: true,
          activeMenu: '/users',
          hidden: true
        }
      },
      {
        path: 'ipwhitelists/create',
        component: () => import(/* webpackChunkName: "admin-create" */ '@/views/ip-whitelist/Create.vue'),
        name: 'create-ipwhitelist',
        meta: {
          title: 'createWhitelist',
          activeMenu: '/ip-whitelist',
          hidden: true
        }
      },
      {
        path: 'ipwhitelists/edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "admin-edit" */ '@/views/ip-whitelist/Edit.vue'),
        name: 'edit-ipwhitelist',
        meta: {
          title: 'editWhitelist',
          noCache: true,
          activeMenu: '/ip-whitelist',
          hidden: true
        }
      },
      {
        path: 'ipwhitelists',
        component: () => import(/* webpackChunkName: "admin-list" */ '@/views/ip-whitelist/List.vue'),
        name: 'list-ipwhitelist',
        meta: {
          title: 'ip-whitelist',
          icon: '#icon-whitelist'
        }
      }
    ]
  }
];

export default userRouter;
