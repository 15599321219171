import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const channelRouter: Array<RouteRecordRaw> = [
  {
    path: '/channels',
    component: Layout,
    // redirect: 'noRedirect',
    meta: {
      title: 'channels',
      icon: '#icon-channel'
    },
    children: [
      {
        path: 'create',
        component: () => import(/* webpackChunkName: "channel-create" */ '@/views/channels/Create.vue'),
        name: 'create-channel',
        meta: {
          title: 'createChannel',
          activeMenu: '/channels',
          hidden: true
        }
      },
      {
        path: 'edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "channel-edit" */ '@/views/channels/Edit.vue'),
        name: 'edit-channel',
        meta: {
          title: 'editChannel',
          noCache: true,
          activeMenu: '/channels',
          hidden: true
        }
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "channel-list" */ '@/views/channels/List.vue'),
        name: 'list-channels',
        meta: {
          title: 'channels',
          icon: '#icon-channel'
        }
      },
      {
        path: ':id(\\d+)',
        component: () => import(/* webpackChunkName: "channel-show" */ '@/views/channels/Show.vue'),
        name: 'show-channel',
        meta: {
          title: 'showChannel',
          noCache: true,
          activeMenu: '/channels',
          hidden: true
        }
      },
      {
        path: ':id(\\d+)/campaigns/create',
        component: () => import(/* webpackChunkName: "campaign-create" */ '@/views/channels/campaigns/Create.vue'),
        name: 'create-channel-campaign',
        meta: {
          title: 'CreateCampaign',
          noCache: true,
          activeMenu: '/channels',
          hidden: true
        }
      },
      {
        path: ':id(\\d+)/campaigns/:cid(\\d+)/edit',
        component: () => import(/* webpackChunkName: "campaign-edit" */ '@/views/channels/campaigns/Edit.vue'),
        name: 'edit-channel-campaign',
        meta: {
          title: 'EditCampaign',
          noCache: true,
          activeMenu: '/channels',
          hidden: true
        }
      }
    ]
  }
];

export default channelRouter;
