import { createApp, Directive } from 'vue';

import App from './App.vue';
// import './pwa/registerServiceWorker'
import router from './router';
import { store } from './store';
import { loadAllPlugins } from './plugins';
import '@/assets/iconfont/iconfont.css';
import '@/styles/index.scss';
import 'normalize.css';
import * as directives from '@/directives';
import '@/permission';

import { registerEasterEgg } from '@/utils/easter-egg';

// 统一导入el-icon图标
import * as ElIconModules from '@element-plus/icons';

const app = createApp(App);

// 加载所有插件
loadAllPlugins(app);

// 自定义指令
Object.keys(directives).forEach(key => {
  app.directive(key, (directives as { [key: string]: Directive })[key]);
});

app
  .use(store)
  .use(router)
  .mount('#app');

function transElIconName(iconName) {
  return 'i' + iconName.replace(/[A-Z]/g, match => '-' + match.toLowerCase());
}

// 导入转换图标名称的函数
// 统一注册el-icon图标
for (const iconName in ElIconModules) {
  app.component(transElIconName(iconName), ElIconModules[iconName]);
}
// utils/utils.js
// 将el-icon的组件名称AbbbCddd转化为i-abbb-cddd形式
// 此前用switch做组件名时因关键字重复报错，所以格式统一加了前缀
// 例：Switch转换为i-switch，ArrowDownBold转换为i-arrow-down-bold

registerEasterEgg();
