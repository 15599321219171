import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const PermissionsRouter: Array<RouteRecordRaw> = [
  {
    path: '/permissions',
    component: Layout,
    // redirect: 'noRedirect',
    meta: {
      title: 'permissions',
      icon: '#icon-rolepermission'
    },
    children: [
      {
        path: 'admins/create',
        component: () => import(/* webpackChunkName: "admin-create" */ '@/views/admins/Create.vue'),
        name: 'create-admin',
        meta: {
          title: 'createAdmin',
          activeMenu: '/admins',
          hidden: true
        }
      },
      {
        path: 'admins/edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "admin-edit" */ '@/views/admins/Edit.vue'),
        name: 'edit-admin',
        meta: {
          title: 'editAdmin',
          noCache: true,
          activeMenu: '/admins',
          hidden: true
        }
      },
      {
        path: 'admins',
        component: () => import(/* webpackChunkName: "admin-list" */ '@/views/admins/List.vue'),
        name: 'list-admins',
        meta: {
          title: 'admins',
          icon: '#icon-admin'
        }
      },
      {
        path: 'admins/:id(\\d+)',
        component: () => import(/* webpackChunkName: "admin-show" */ '@/views/admins/Show.vue'),
        name: 'show-admin',
        meta: {
          title: 'showAdmin',
          noCache: true,
          activeMenu: '/admins',
          hidden: true
        }
      },
      {
        path: 'roles/create',
        component: () => import(/* webpackChunkName: "role-create" */ '@/views/roles/Create.vue'),
        name: 'create-role',
        meta: {
          title: 'createRole',
          activeMenu: '/roles',
          hidden: true
        }
      },
      {
        path: 'roles/edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "role-edit" */ '@/views/roles/Edit.vue'),
        name: 'edit-role',
        meta: {
          title: 'editRole',
          noCache: true,
          activeMenu: '/roles',
          hidden: true
        }
      },
      {
        path: 'roles',
        component: () => import(/* webpackChunkName: "role-list" */ '@/views/roles/List.vue'),
        name: 'list-roles',
        meta: {
          title: 'roles',
          icon: '#icon-role'
        }
      }
    ]
  }
];

export default PermissionsRouter;
