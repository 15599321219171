/*
 * @Description:
 * @Author: ZY
 * @Date: 2020-12-25 14:28:43
 * @LastEditors: ZY
 * @LastEditTime: 2020-12-25 14:32:35
 */
export enum PermissionMutationType{
    SET_ROUTES = 'SET_ROUTES'
}
