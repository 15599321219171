import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-454f29fb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "drawer-container" }
const _hoisted_2 = { class: "drawer-title" }
const _hoisted_3 = { class: "drawer-item" }
const _hoisted_4 = { class: "drawer-item" }
const _hoisted_5 = { class: "drawer-item" }
const _hoisted_6 = { class: "drawer-item" }
const _hoisted_7 = { class: "drawer-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ThemePicker = _resolveComponent("ThemePicker")!
  const _component_el_switch = _resolveComponent("el-switch")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.t('settings.title')), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", null, _toDisplayString(_ctx.t('settings.theme')), 1),
        _createVNode(_component_ThemePicker, {
          style: {"float":"right","height":"26px","margin":"-3px 8px 0 0"},
          onChange: _ctx.themeChange
        }, null, 8, ["onChange"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", null, _toDisplayString(_ctx.t('settings.showTagsView')), 1),
        _createVNode(_component_el_switch, {
          modelValue: _ctx.showTagsView,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showTagsView) = $event)),
          class: "drawer-switch"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", null, _toDisplayString(_ctx.t('settings.showSidebarLogo')), 1),
        _createVNode(_component_el_switch, {
          modelValue: _ctx.showSidebarLogo,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showSidebarLogo) = $event)),
          class: "drawer-switch"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("span", null, _toDisplayString(_ctx.t('settings.fixedHeader')), 1),
        _createVNode(_component_el_switch, {
          modelValue: _ctx.fixedHeader,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.fixedHeader) = $event)),
          class: "drawer-switch"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("span", null, _toDisplayString(_ctx.t('settings.sidebarTextTheme')), 1),
        _createVNode(_component_el_switch, {
          modelValue: _ctx.sidebarTextTheme,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.sidebarTextTheme) = $event)),
          class: "drawer-switch"
        }, null, 8, ["modelValue"])
      ])
    ])
  ]))
}