import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const directorRouter: Array<RouteRecordRaw> = [
  {
    path: '/directors',
    component: Layout,
    // redirect: 'noRedirect',
    meta: {
      title: 'directors',
      icon: '#icon-director'
    },
    children: [
      {
        path: 'create',
        component: () => import(/* webpackChunkName: "directors-create" */ '@/views/directors/Create.vue'),
        name: 'create-director',
        meta: {
          title: 'createDirector',
          activeMenu: '/directors',
          hidden: true
        }
      },
      {
        path: 'edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "directors-edit" */ '@/views/directors/Edit.vue'),
        name: 'edit-director',
        meta: {
          title: 'editDirector',
          noCache: true,
          activeMenu: '/directors',
          hidden: true
        }
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "directors-list" */ '@/views/directors/List.vue'),
        name: 'list-directors',
        meta: {
          title: 'directors',
          icon: '#icon-director'
        }
      },
      {
        path: ':id(\\d+)',
        component: () => import(/* webpackChunkName: "directors-show" */ '@/views/directors/Show.vue'),
        name: 'show-director',
        meta: {
          title: 'showDirector',
          noCache: true,
          activeMenu: '/directors',
          hidden: true
        }
      }
    ]
  }
];

export default directorRouter;
