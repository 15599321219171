
import { useStore } from '@/store';
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  setup() {
    const store = useStore();
    const route = useRoute();
    const cachedViews = () => {
      return store.state.tagViews.cachedViews;
    };
    const key = () => {
      return route.path;
    };
    return {
      cachedViews,
      key
    };
  }
});
