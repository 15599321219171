import { request } from './utils';

import {
  PhotoResponse,
  PhotosResponse,
  GetPhotoRequestOptions,
  GetPhotosRequestOptions,
  CreatePhotoRequestOptions,
  UpdatePhotoRequestOptions,
  DeletePhotoRequestOptions,
  SucceededResponse
} from './interfaces';

export function getPhoto({ photoId }: GetPhotoRequestOptions): Promise<PhotoResponse> {
  return request({
    url: `/photos/${photoId}`,
    method: 'get'
  });
}

export function getPhotos({ query }: GetPhotosRequestOptions = {}): Promise<PhotosResponse> {
  return request({
    url: '/photos',
    method: 'get',
    params: query
  });
}

export function createPhoto({ data }: CreatePhotoRequestOptions): Promise<PhotoResponse> {
  return request({
    url: '/photos',
    method: 'post',
    data
  });
}

export function updatePhoto({ photoId, data }: UpdatePhotoRequestOptions): Promise<PhotoResponse> {
  return request({
    url: `/photos/${photoId}`,
    method: 'put',
    data
  });
}

export function deletePhoto({ photoId }: DeletePhotoRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/photos/${photoId}`,
    method: 'delete'
  });
}
