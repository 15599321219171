import { request } from './utils';

import {
  SiteResponse,
  SitesResponse,
  GetSiteRequestOptions,
  GetSitesRequestOptions,
  CreateSiteRequestOptions,
  UpdateSiteRequestOptions,
  DeleteSiteRequestOptions,
  SucceededResponse
} from './interfaces';

export function getSite({ siteId }: GetSiteRequestOptions): Promise<SiteResponse> {
  return request({
    url: `/sites/${siteId}`,
    method: 'get'
  });
}

export function getSites({ query }: GetSitesRequestOptions = {}): Promise<SitesResponse> {
  return request({
    url: '/sites',
    method: 'get',
    params: query
  });
}

export function createSite({ data }: CreateSiteRequestOptions): Promise<SiteResponse> {
  return request({
    url: '/sites',
    method: 'post',
    data
  });
}

export function updateSite({ siteId, data }: UpdateSiteRequestOptions): Promise<SiteResponse> {
  return request({
    url: `/sites/${siteId}`,
    method: 'put',
    data
  });
}

export function deleteSite({ siteId }: DeleteSiteRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/sites/${siteId}`,
    method: 'delete'
  });
}
