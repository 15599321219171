import { request } from './utils';

import {
  AdvertisementResponse,
  AdvertisementsResponse,
  GetAdvertisementRequestOptions,
  GetAdvertisementsRequestOptions,
  UpdateAdvertisementRequestOptions
} from './interfaces';

export function getAdvertisement({ advertisementsId }: GetAdvertisementRequestOptions): Promise<AdvertisementResponse> {
  return request({
    url: `/advertisements/${advertisementsId}`,
    method: 'get'
  });
}

export function getAdvertisements({ query }: GetAdvertisementsRequestOptions): Promise<AdvertisementsResponse> {
  return request({
    url: '/advertisements',
    method: 'get',
    params: query
  });
}

export function updateAdvertisement({ advertisementsId, data }: UpdateAdvertisementRequestOptions): Promise<AdvertisementResponse> {
  return request({
    url: `/advertisements/${advertisementsId}`,
    method: 'put',
    data
  });
}
