import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const tagRouter: Array<RouteRecordRaw> = [
  {
    path: '/tags',
    component: Layout,
    // redirect: 'noRedirect',
    meta: {
      title: 'tags',
      icon: '#icon-tag'
    },
    children: [
      {
        path: 'create',
        component: () => import(/* webpackChunkName: "tag-create" */ '@/views/tags/Create.vue'),
        name: 'create-tag',
        meta: {
          title: 'createTag',
          activeMenu: '/tags',
          hidden: true
        }
      },
      {
        path: 'edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "tag-edit" */ '@/views/tags/Edit.vue'),
        name: 'edit-tag',
        meta: {
          title: 'editTag',
          noCache: true,
          activeMenu: '/tags',
          hidden: true
        }
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "tag-list" */ '@/views/tags/List.vue'),
        name: 'list-tags',
        meta: {
          title: 'tags',
          icon: '#icon-tag'
        }
      }
    ]
  }
];

export default tagRouter;
