
import { defineComponent, onBeforeMount, reactive, toRefs, watch } from 'vue';
import { useRoute, RouteLocationMatched } from 'vue-router';
import { compile } from 'path-to-regexp';
import { useI18n } from 'vue-i18n';
import router from '@/router';
export default defineComponent({
  setup() {
    const currentRoute = useRoute();
    const pathCompile = (path: string) => {
      const { params } = currentRoute;
      const toPath = compile(path);
      return toPath(params);
    };

    const { t } = useI18n();
    const state = reactive({
      breadcrumbs: [] as Array<RouteLocationMatched>,
      getBreadcrumb: () => {
        let matched = currentRoute.matched.filter((item) => item.meta && item.meta.title);
        const frist = matched[0];
        if (!state.isDashboard(frist)) {
          matched = [{ path: '/dashboard', meta: { title: 'dashboard' } } as any].concat(matched);
        }
        state.breadcrumbs = matched.filter((item) => {
          return item.meta && item.meta.title && item.meta.breadcrumb !== false;
        });
      },
      isDashboard(route: RouteLocationMatched) {
        const name = route && route.name;
        if (!name) {
          return false;
        }
        return name.toString().trim().toLocaleLowerCase() === 'Dashboard'.toLocaleLowerCase();
      },
      handleLink(item: any) {
        const { redirect, path } = item;
        if (redirect) {
          router.push(redirect).catch((err) => {
            console.warn(err);
          });
          return;
        }
        router.push(pathCompile(path)).catch((err) => {
          console.warn(err);
        });
      }
    });

    watch(() => currentRoute.path, (path) => {
      if (path.startsWith('/redirect/')) {
        return;
      }
      state.getBreadcrumb();
    });

    onBeforeMount(() => {
      state.getBreadcrumb();
    });

    return {
      t,
      ...toRefs(state)
    };
  }

});

