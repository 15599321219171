import { request } from './utils';

import {
  LoginRequestOptions,
  LoginResponse,
  AdminResponse,
  GoogleOptauthRequestOptions,
  GoogleVerifyRequestOptions,
  Response
} from './interfaces';

export function login({
  data
}: LoginRequestOptions): Promise<LoginResponse> {
  return request({
    url: '/login',
    method: 'post',
    data
  });
}

export function getMe(): Promise<AdminResponse> {
  return request({
    url: '/auth/me',
    method: 'get'
  });
}

export function googleOtpauth({
  data
}: GoogleOptauthRequestOptions): Promise<Response> {
  return request({
    url: '/googleOtpauth',
    method: 'post',
    data
  });
}

export function googleVerify({
  data
}: GoogleVerifyRequestOptions): Promise<Response> {
  return request({
    url: '/googleVerify',
    method: 'post',
    data
  });
}
