import { request } from './utils';

import {
  AdminResponse,
  AdminsResponse,
  GetAdminRequestOptions,
  GetAdminsRequestOptions,
  CreateAdminRequestOptions,
  UpdateAdminRequestOptions,
  DeleteAdminRequestOptions,
  EnableAdminRequestOptions,
  DisableAdminRequestOptions,
  GetAdminAllocatedMembersRequestOptions,
  AdminAllocatedMembersResponse,
  SucceededResponse
} from './interfaces';

export function getAdmin({ adminId }: GetAdminRequestOptions): Promise<AdminResponse> {
  return request({
    url: `/admins/${adminId}`,
    method: 'get'
  });
}

export function getAdmins({ query }: GetAdminsRequestOptions = {}): Promise<AdminsResponse> {
  return request({
    url: '/admins',
    method: 'get',
    params: query
  });
}

export function createAdmin({ data }: CreateAdminRequestOptions): Promise<AdminResponse> {
  return request({
    url: '/admins',
    method: 'post',
    data
  });
}

export function updateAdmin({ adminId, data }: UpdateAdminRequestOptions): Promise<AdminResponse> {
  return request({
    url: `/admins/${adminId}`,
    method: 'put',
    data
  });
}

export function deleteAdmin({ adminId }: DeleteAdminRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/admins/${adminId}`,
    method: 'delete'
  });
}

export function resetQRCodeAdmin({ adminId }: DeleteAdminRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/admins/${adminId}/resetQRCode`,
    method: 'post'
  });
}

export function enableAdmin({ adminId }: EnableAdminRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/admins/${adminId}/enable`,
    method: 'post'
  });
}

export function disableAdmin({ adminId }: DisableAdminRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/admins/${adminId}/disable`,
    method: 'post'
  });
}

export function getAdminAllocatedMembers({ adminId, query }: GetAdminAllocatedMembersRequestOptions): Promise<AdminAllocatedMembersResponse> {
  return request({
    url: `/admins/${adminId}/allocated-members`,
    method: 'get',
    params: query
  });
}
