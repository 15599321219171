/*
 * @Description:
 * @Author: ZY
 * @Date: 2020-12-18 15:23:57
 * @LastEditors: ZY
 * @LastEditTime: 2021-01-20 11:12:08
 */
import { createI18n } from 'vue-i18n'; // import from runtime only

import { getLanguage } from '@/utils/cookies';

import elementEnLocale from 'element-plus/lib/locale/lang/en';
import elementZhLocale from 'element-plus/lib/locale/lang/zh-cn';

// User defined lang
import enLocale from './en';
import zhLocale from './zh-cn';

const DEFAULT_LANG = 'en';

const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale
  },
  'zh-cn': {
    ...zhLocale,
    ...elementZhLocale
  }
};

export const getLocale = () => {
  const cookieLanguage = getLanguage();
  if (cookieLanguage) {
    return cookieLanguage;
  }

  const language = navigator.language.toLowerCase();
  const locales = Object.keys(messages);
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }

  return DEFAULT_LANG;
};

const i18n = createI18n({
  locale: getLocale(),
  messages: messages
});

export default i18n;
